var $name="analytics/index",$path="app/modules/analytics/index.js",$this={$name,$path,};import shell from 'app/modules/shell';
import forEach from 'lodash/forEach';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';

import { cartProductSelectorWidgetProvider } from './cart';
import { chatActionProvider } from './chat';
import { contactSubmitProvider } from './contact';
import { downloadProvider } from './download';
import getConfig from './getConfig';
import getDataLayer from './getDataLayer';
import { exitToSchneiderProvider } from './exitToSchneider';
import { newsletterSubscriptionProvider } from './newsletter';
import { pageviewProvider } from './pageview';
import { ctaLinkProvider } from './ctaLink';

const events = {
  addToCart: 'addToCart',
  callSupportNow: 'callSupportNow',
  chatAction: 'chatAction',
  contactSubmit: 'contactSubmit',
  download: 'download',
  newsletterSubscription: 'newsletterSubscription',
  virtualPageview: 'virtualPageview',
  exitToSchneider: 'exit_to_schneider',
  cta: 'cta_click',
};

const domains = {
  apc: 'apc.com',
  se: 'se.com',
  seMainSite: 'SE Main site',
};

export default shell.registerModule($this, ({
  addEventListener, log, mount, settings,
}) => {
  const track = (provider) => Promise.race([
    new Promise(
      (eventCallback) => {
        if (settings.analytics) {
          const variables = provider(getConfig(), settings);
          log.info('Tracking:\n', variables);
          getDataLayer().push({ ...variables, eventCallback });
        } else {
          eventCallback();
        }
      },
    ),
    new Promise(
      (timeoutCallback) => setTimeout(timeoutCallback, 1000),
    ),
  ]);

  /**
   * @template T
   * @param { string } [event]
   * @param { (variables: T, config: object, settings: object ) => object } [provider]
   * @returns { (variables: T) => void }
   */
  function createTracker(event, provider) {
    const parameters = [event, provider];
    /* eslint-disable no-param-reassign */
    event = null;
    provider = null;
    for (const parameter of parameters) {
      if (isString(parameter)) {
        event = parameter;
      } else if (isFunction(parameter)) {
        provider = parameter;
      } else if (!isUndefined(parameter)) {
        log.warn('Unexpected type of parameter for tracker:', parameter);
      }
    }
    /* eslint-enable no-param-reassign */

    if (event != null) {
      return provider == null
        ? () => track(() => ({ event }))
        : (variables) => track((...args) => ({ event, ...provider(variables, ...args) }));
    }
    if (provider != null) {
      return (variables) => track((...args) => provider(variables, ...args));
    }
    log.warn('Insufficient number of parameters for tracker:\n', parameters);
    return () => {
      log.warn('Attempt to track a misconfigured event');
    };
  }

  const trackAddToCartViaProductSelectorWidget = createTracker(events.addToCart, cartProductSelectorWidgetProvider);
  const trackCallSupportNow = createTracker(events.callSupportNow);
  const trackChatAction = createTracker(events.chatAction, chatActionProvider);
  const trackContactSubmit = createTracker(events.contactSubmit, contactSubmitProvider);
  const trackDownload = createTracker(events.download, downloadProvider);
  const trackNewsletterSubscription = createTracker(events.newsletterSubscription, newsletterSubscriptionProvider);
  const trackVirtualPageview = createTracker(events.virtualPageview, pageviewProvider);
  const trackExitToSchneider = createTracker(events.exitToSchneider, exitToSchneiderProvider);
  const trackCTA = createTracker(events.cta, ctaLinkProvider);

  const onDownloadLinkClick = ({ target }) => trackDownload({ documentURL: target?.href ?? target.closest('a')?.href });
  const onExitToSchneiderClick = ({ target }) => trackExitToSchneider({
    linkUrl: target?.href ?? target.closest('a')?.href,
  });
  const onCTAClick = ({ target }) => trackCTA({
    cta_link: target?.href ?? (target.closest('a')?.href || target.closest('button')?.dataset?.href),
    cta_name: target?.dataset?.name ?? (target.closest('a')?.dataset?.name || target.closest('button')?.dataset?.name),
    cta_location: target?.dataset?.tmp ?? (target.closest('a')?.tmp || target.closest('button')?.tmp),
  });
  // eslint-disable-next-line max-len
  const selectDownloadLinks = (element) => element.querySelectorAll(`a[href*="${getConfig().triggerDownload}"][href*="p_Doc_Ref"]`);
  const selectCallSupportLinks = (element) => element.querySelectorAll(
    `a[href^="${getConfig().callSupportNowMarker}"]`,
  );
  const selectExitToSchneiderLinks = (element) => element.querySelectorAll(
    `ul[class*="brand-list"] li a, a[href*="${domains.se}"], a[href*="${domains.apc}"]`,
  );
  const selectCTALinks = (element) => element.querySelectorAll(
    `a.se2--single-link,
    a.se2--link-cta,
    a.stretched-link, 
    .se2--link-list a,
    button.se2--link-cta`,
  );
  const setUpOnDownloadLinkClickTracker = (element) => {
    if (settings.analytics && getConfig().triggerDownload && !getConfig().isConsumerPage) {
      forEach(
        selectDownloadLinks(element),
        (link) => {
          addEventListener(link, 'click', onDownloadLinkClick);
        },
      );
    }
  };

  const setUptrackCallSupportNowTracker = (element) => {
    if (settings.analytics && !getConfig().isConsumerPage) {
      forEach(
        selectCallSupportLinks(element),
        (link) => {
          addEventListener(link, 'click', trackCallSupportNow);
        },
      );
    }
  };

  const setUpOnExitToSchneiderClickTracker = (element) => {
    const { digitalPlatform, isConsumerPage, exitToSchneider } = getConfig();
    const isDigitalPlatform = digitalPlatform === domains.apc || digitalPlatform === domains.seMainSite;
    if (settings.analytics && !isConsumerPage && isDigitalPlatform && exitToSchneider) {
      forEach(
        selectExitToSchneiderLinks(element),
        (link) => {
          addEventListener(link, 'click', onExitToSchneiderClick);
        },
      );
    }
  };

  const setUpCTAClickTracker = (element) => {
    if (settings.analytics && !getConfig().isConsumerPage && getConfig().ctaClick) {
      forEach(Array.from(selectCTALinks(element))
        .filter((el) => !el.closest('nav'))
        .filter((el) => !el.closest('header'))
        .filter((el) => !el.closest('footer')),
      (link) => {
        addEventListener(link, 'click', onCTAClick);
      });
    }
  };

  mount((element) => {
    setUpOnDownloadLinkClickTracker(element);
    setUptrackCallSupportNowTracker(element);
    setUpOnExitToSchneiderClickTracker(element);
    setUpCTAClickTracker(element);
  });

  // TODO: activate GA script

  return {
    events,
    trackAddToCartViaProductSelectorWidget,
    trackCallSupportNow,
    trackChatAction,
    trackContactSubmit,
    trackDownload,
    trackExitToSchneider,
    trackNewsletterSubscription,
    trackVirtualPageview,
    setUpOnDownloadLinkClickTracker,
    setUptrackCallSupportNowTracker,
    setUpOnExitToSchneiderClickTracker,
    setUpCTAClickTracker,
  };
});
