var $class="se2--utilities",$name="utilities",$path="app/components/utilities/checkIsIncorrect.js",$this={$class,$name,$path,};import every from 'lodash/every';
import { emailPattern } from 'configs/validationPatterns';

const formGroupClassName = 'form-group';

function checkValidity(form) {
  const emailField = form.querySelector('input[name=email]');
  const emailFooter = form.querySelector('#sdl-footer-seemail');

  if (emailField?.value) {
    if (emailPattern.test(emailField.value)) {
      emailField?.closest(`.${formGroupClassName}`).classList.remove('incorrect');
    } else {
      emailField?.closest(`.${formGroupClassName}`).classList.add('incorrect');
    }
  } else {
    emailField?.closest(`.${formGroupClassName}`).classList.remove('incorrect');
  }

  if (emailFooter?.value) {
    if (emailPattern.test(emailFooter.value)) {
      emailFooter?.closest(`.${formGroupClassName}`).classList.remove('invalid');
    } else {
      emailFooter?.closest(`.${formGroupClassName}`).classList.add('invalid');
    }
  }

  if (form.classList.contains('incorrect') || form.classList.contains('invalid')) {
    return false;
  }
  return true;
}

const checkIsIncorrect = (form) => every(
  form.querySelectorAll(`.${formGroupClassName}`),
  checkValidity,
);

export default checkIsIncorrect;
