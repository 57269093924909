var $class="se2--footer-global-se",$name="FooterGlobalSE",$path="app/components/FooterGlobalSE/config.js",$this={$class,$name,$path,};export const footerClassName = $class;
export const footerSelector = `.${$class}`;
export const footerCheckboxContainerSelector = '.footer-checkbox-container';
export const footerCheckboxClassName = 'footer-checkbox';
export const footerChinaCheckboxClassName = 'footer-china-checkbox';
export const inputCheckboxSelector = '.input-checkbox';
export const cookiePrivacyLinkClassName = 'cookie-privacy-link';
export const cookiePrivacyOneTrustLinkClassName = 'ot-sdk-show-settings';
export const cookiePrivacyKeyword = '--TC--';
export const linksContainerSelector = '.links-container';
export const cardLinkSelector = '.card-link';
